.home-bg {
  background-image: url("../assets/back3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 95vh;
}

.home-left {
  margin-top: 200px;
  margin-left: 150px;
  width: 60vw;
  font-size: 70px;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.home-right {
  width: 30vw;
}

@media screen and (max-width: 430px) {
  .home-left {
    font-size: 40px;
    margin: auto;
  }
}
