.footer {
  width: 100%;
  height: 15vh;
  background-color: black;
  color: white;
  text-align: center;
  line-height: 7;
  font-weight: 600;
  margin-top: 100px;
}
