.products-container {
  width: 80vw;
  margin: auto;
  margin-top: 50px;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 430px) {
  .btn {
    margin-top: 10px;
    display: flex;
    margin: auto;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 380px) {
  .card-body .btn {
    margin-bottom: -15px;
  }
  .card-body p {
    margin-bottom: 10px;
  }
  .card {
    height: 30px;
  }
}
