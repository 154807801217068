.partner-container img {
  width: 15vw;
  margin: 10px;
}

.partner-container {
  width: 90vw;
  margin: auto;
}
h1 {
  margin: auto;
  font-family: "Montserrat", sans-serif;
}

.mini-footer {
  background-image: url("../assets/back3.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 25vh;
}
.mini-footer h2 {
  font-size: 40px;
}

@media screen and (max-width: 425px) {
  .partners {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .partners img {
    width: 200px;
  }
  .mini-footer h2 {
    text-align: center;
  }
}
