.cards-container {
  display: flex;
  justify-content: center;
  gap: 90px;
  border: none;
}

.card {
  width: 22vw;
  border-color: #ffca3d;
  text-align: center;
}

.card i {
  font-size: 40px;
}
.card p {
  font-family: "Montserrat", sans-serif;
}

.card h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
}

@media screen and (max-width: 430px) {
  .cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card {
    width: 80vw;
  }
}
